import OneSignal from "react-onesignal";

export async function oneSignalLogin(authId: string) {
  if (process.env.NODE_ENV !== "development") {
    if (!(window as any).nativeBridge) {
      await OneSignal.login(authId.toUpperCase());
    }
  }
}

export async function oneSignalLogout() {
  if (process.env.NODE_ENV !== "development") {
    if (!(window as any).nativeBridge) {
      await OneSignal.logout();
    }
  }
}

export async function runOneSignal() {
  if (process.env.NODE_ENV !== "development") {
    if (!(window as any).nativeBridge) {
      await OneSignal.init({
        appId: "a5de9470-dc25-4fb9-89d2-c19ee4675b7d",
        safari_web_id:
          "web.onesignal.auto.0a17e090-f65a-43cf-871a-056959ed633a",
        serviceWorkerParam: { scope: "/onesignal/" },
        serviceWorkerPath: "onesignal/OneSignalSDKWorker.js",
      });
    }
  }
}
