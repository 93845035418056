import { useBackendStore } from "@discoverr/web/backend";
import { combine, create } from "@discoverr/web/store";

export const useSpaceTranslation = create(
  combine(
    {
      initialized: false,
      language: null as string | null,
      translations: undefined as undefined | null | Record<string, string>,
    },
    (set, get) => ({
      async initTranslations(lang: null | string) {
        if (lang && lang !== get().language) {
          try {
            if (get().initialized !== false) {
              set({ initialized: false, language: lang });
            }
            const res = await useBackendStore
              .getState()
              .backend.get(`/drive.discoverr.io/Public/ncngroup/${lang}.json`, {
                baseURL: "https://s3.eu-central-1.wasabisys.com",
              });

            if (res.data) {
              set({
                translations: res.data,
                initialized: true,
                language: lang,
              });
            }
          } catch (error) {
            set({ translations: null, initialized: true });
          }
        } else {
          set({ translations: null, initialized: true, language: null });
        }
      },
      spaceTranslate(
        defaultTranslation: string,
        ...args: Array<string | number>
      ) {
        const translations = get().translations;
        let draft =
          translations && translations[defaultTranslation]
            ? translations[defaultTranslation]
            : defaultTranslation;
        if (args.length) {
          for (let i = 0; i < args.length; i++) {
            draft = draft.replace(`{{${i}}}`, `${args[i]}`);
          }
        }
        return draft;
      },
    })
  )
);

export const spaceTranslate = (
  defaultTranslation: string,
  ...args: Array<string | number>
) => useSpaceTranslation.getState().spaceTranslate(defaultTranslation, ...args);
