import { create, combine } from "@discoverr/web/store";

const messageListener = window.addEventListener("message", (nativeEvent) => {
  if (nativeEvent?.data.type === "nativeBridge") {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "webBridge",
          text: "Hi from PWA",
        })
      );
    }
  }
});

const postMessage = (
  payload: Record<string, string | number | boolean | null>
) => {
  if ((window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload));
  }
};

//
export const useNativeBridge = create(
  combine(
    {
      nativeBridge: !!(window as any).nativeBridge,
    },
    (set, get) => ({
      setAuthId(authId: string | null) {
        postMessage({
          type: "webBridge",
          message: "auth",
          authId: authId,
        });
      },
      toggleEnv() {
        postMessage({
          type: "webBridge",
          message: "setEnv",
        });
      },
    })
  )
);
